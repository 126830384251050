import httpClient from "@/api/httpClient";

const END_POINT = '/communications';

const createCommunication = (communication: any) => httpClient.post(
    END_POINT,
    { ...communication }
);

const paginateCommunication = (page: number = 1) => httpClient.get(
    END_POINT + '?page=' + page
);

const showCommunication = (id: number) => httpClient.get(
    END_POINT + '/' + id
);

const updateCommunication = (id: number, communication: any) => httpClient.patch(
    END_POINT + '/' + id,
    { ...communication }
);

const deleteCommunication = (id: number) => httpClient.delete(
    END_POINT + '/' + id
)

const showAllCommunications = () => httpClient.get(
    END_POINT + '/all'
)

const userCommunications = (page = 1) => httpClient.get(
    END_POINT + '/list?page=' + page
)

const sendCommunication = (emails: [], communicationId: number) => httpClient.post(
    END_POINT + '/send',
    {
        emails,
        communicationId
    }
)

export {
    createCommunication,
    paginateCommunication,
    showCommunication,
    updateCommunication,
    deleteCommunication,
    showAllCommunications,
    sendCommunication,
    userCommunications
}