<template>
  <div>
    <button
      class="btn bg-success me-3"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
    >
      <i class="bi bi-plus-lg"></i>
      Crea
    </button>

    <button
      class="btn bg-warning"
    >
      <router-link :to="'/management/communications/send'">
        <i class="bi bi-forward"></i>
        Invia
      </router-link>
    </button>
    <div class="mb-4"></div>
    <Table
        :headings="tableHeadings"
        :data="articles"
        :loading="isLoading"
        @actionView="actionView"
        @actionDelete="actionDelete"
    >
    </Table>
    <v-pagination
        v-model="pageNumber"
        :pages="meta.last_page"
        @update:modelValue="updateHandler"
    >
    </v-pagination>
  </div>

  <Modal
    :title="'Crea una comunicazione'"
  >
    <label for="title">Titolo</label>
    <input id="title" type="text" class="form-control mb-3" v-model="communication.title" />

    <label for="content">Contenuto</label>
    <textarea id="content" class="form-control" rows="10" v-model="communication.content">
    </textarea>

    <button class="btn bg-blue mt-4" @click="create">
      Salva
    </button>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Table from '@/components/table/Table.vue';
import { Paginator } from "@/types/Paginator";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import "@/assets/css/paginator.css";
import { mapGetters } from "vuex";
import {createCommunication, deleteCommunication, paginateCommunication} from "@/api/communication.api";
import { sweetAlertCallback, sweetAlertSuccess } from "@/utilities/sweetalert";
import Modal from "@/components/modals/Modal.vue";

export default defineComponent({
  name: "CommunicationsList",
  mixins: [spinnerIsLoading],
  components: {
    Modal,
    Table,
    VPagination,
  },
  data() {
    return {
      tableHeadings: {
        id: '#',
        title: 'Titolo',
        created_at: 'Creato il'
      },
      meta: {} as Paginator,
      articles: [] as any[],
      pageNumber: 1 as number,
      communication: {},
    }
  },
  methods: {
    async create() {
      if (!this.communication) return;

      const response: any = await createCommunication(this.communication);
      if (response.data) {
        await sweetAlertSuccess('success', 'Comunicazione Creata con successo');
        this.getCommunications(1);
        this.closeModal();
        this.reset();
        return;
      }

      await sweetAlertSuccess('error', 'Si è verificato un errore');
      this.closeModal();
      this.reset();
    },
    reset() {
      this.communication = {};
    },
    closeModal() {
      (document.querySelector('.close-modal') as HTMLElement)?.click();
    },
    async getCommunications(pageNumber: number) {
      this.startLoading();

      const response: any = await paginateCommunication(pageNumber);

      this.articles = response.data;
      this.meta = response.meta;

      this.stopLoading();
    },
    updateHandler(page: number) {
      this.pageNumber = page;

      this.getCommunications(page);
    },
    actionView(id: number) {
      this.$router.push({ name: 'HandleSpecificNews', params: { id } })
    },
    async actionDelete(data: any) {
      const id = data.id;

      sweetAlertCallback('Sei sicuro di voler eliminare definitivamente l\'articolo?')
          .then(async (result) => {
            if (!result.isConfirmed) return;

            const response = await deleteCommunication(id);

            if (!response) return;

            const index = this.articles.findIndex((communications: any) => communications.id === id);

            if (index <= -1) return;

            this.articles.splice(index, 1);

            return sweetAlertSuccess('success','Articolo eliminato con successo');
          });
    }
  },
  computed: {
    ...mapGetters([
      'user',
    ])
  },
  mounted() {
    if (!this.user.is_admin) {
      return this.$router.push({ name: 'Dashboard' });
    }

    this.getCommunications(this.pageNumber);
  }
})
</script>

<style scoped>
button,
.form-select,
.form-control {
  border-radius: 22px;
}

label {
  font-weight: 600;
  margin-left: 0.8rem;
  color: #173759;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: #fff;
}

</style>